<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Gestione del Personale </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon icon="HomeIcon" scale="1.25" shift-v="1.25"></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Account Backoffice</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div>
          <b-button variant="primary" @click="$router.push('/account/backoffice/add')">
            Crea Account Backoffice</b-button>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" />
          </div>
        </b-col>
      </b-row>

      <b-table :fields="fields" :items="accountProvider" :busy="loading" :filter="filter" :current-page="currentPage"
        striped responsive show-empty @sort-changed="sortTable">
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati account...</strong>
          </div>
        </template>

        <template #empty>
          <h5 class="text-primary-light text-center my-2">
            Non ci sono Account Backoffice
          </h5>
        </template>

        <template #emptyfiltered>
          <h5 class="text-primary-light text-center my-2">
            Non sono stati trovati Account Backoffice
          </h5>
        </template>

        <template #cell(status)="data">
          {{ data.item.account_enabled }}
        </template>

        <template #cell(nome_responsabile)="data">
          {{ data.item.nome }} {{ data.item.cognome }}
        </template>

        <template #cell(telefono_responsabile)="data">
          {{ data.item.cellulare }}
        </template>

        <template #cell(e-mail_responsabile)="data">
          {{ data.item.email }}
        </template>

        <template #cell(opzioni)>
          <span class="text-nowrap">
            <feather-icon class="cursor-pointer mr-2" icon="EyeIcon" size="20"
              v-b-tooltip.hover.top.v-primary-light="'Dettagli'" />
            <feather-icon class="cursor-pointer" icon="EditIcon" size="20"
              v-b-tooltip.hover.top.v-primary-light="'Modifica'" />
          </span>
        </template>
      </b-table>
    </b-card>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BTable,
  BSpinner,
  BFormInput,
  VBTooltip
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BSpinner,
    BFormInput,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data: () => ({
    fields: [
      {
        key: "id",
        sortable: true,
      },
      {
        key: "status",
        sortable: true,
      },
      {
        key: "nome",
        sortable: true,
      },
      {
        key: "cognome",
        sortable: true,
      },
      {
        key: "ubicazione",
        sortable: true,
      },
      {
        key: "telefono_interno",
        sortable: true,
      },
      {
        key: "e-mail",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    filter: null,
    accounts: [],
    sortBy: 'id_user',
    sortDesc: false,

    //Helpers

    perPage: 15,
    currentPage: 1,
    loading: false,
  }),

  methods: {
    async getAccountList(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append('id_role', 4);
      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getAccountList(request_data);
        this.accounts = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async accountProvider() {
      await this.getAccountList();
      return this.accounts.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>
